<template>
  <q-dialog
    v-model="dialog"
    transition-show="slide-up"
    transition-hide="slide-down"
    :maximized="$q.platform.is.mobile ? maximizedToggle : false"
    class="q-pa-md justify-center"
    position="bottom"
  >
    <q-card
      class="q-ma-md q-pa-md"
      :style="{ 'font-family': settings.typography }"
    >
      <q-card-section class="justify-between row q-pt-md q-pb-xs">
        <div class="text-subtitle1">
          {{ $t("BuyingProcess.products.home-delivery") }}
        </div>
      </q-card-section>

      <!-- <q-card-section
        class="text-weight-bold q-ml-md q-ma-none q-pa-none q-mb-xs"
      >
        {{ $t("BuyingProcess.products.new-address") }}
        Elige la direccion de envio
      </q-card-section> -->

      <q-card-section
        class="q-ml-md"
      >
        <q-item-label v-if="addresses.length==0" caption class="q-my-sm text-overline text-weight-bold">
          {{ $t("BuyingProcess.products.no-addresses") }}
        </q-item-label>

        <div v-for="(address, index) in addresses" class="column">
          <q-radio
            v-model="radioChosen"
            :val="index"
            :label="formatAddress(address.address, address.postal_code, address.city, address.province, address.country)"
            class="justify-between text-body2"
            left-label
          />
        </div>

      </q-card-section>
      
      <q-card-section
        class="text-weight-bold q-ml-md"
      >
        <div
          style="cursor: pointer;"
          @click="this.$emit('addNewAddress'); dialog=false"
        > {{ $t('BuyingProcess.transactions.add-new-address') }} </div>
        
      </q-card-section>
      
      <div class="flex justify-center q-mt-lg">
        <q-btn
          class="cursor-pointer text-center"
          :label="$t('General.save')"
          v-close-popup
          @click="chooseAddress()"
          :style="{ backgroundColor: settings.color_accent }"
        />
      </div>
    </q-card>
  </q-dialog>
</template>

<script>
import { defineAsyncComponent, ref } from "vue";
import { mapState } from "vuex";
import settings from "@/mixins/settings";
import { formatAddress } from "@/services/helpers/AddressFormat";



export default {
  mixins: [settings],

  components: {
    

  },
  data() {
    return {
      addressCopy: "",
      radioChosen: null,

    };
  },
  props: {
    dialog: {
      default: false,
    },
    addresses: {
      type: Array,
      default: []
    },
    addressType: {
      type: String,
      default: "shipping"
    },
  },
  emits: ['shippingAddressSelected', 'billingAddressSelected', 'addNewAddress'],
  setup() {
    return {
      maximizedToggle: true,
      color: ref("cyan"),
    };
  },
  computed: {
    ...mapState("auth", ["user"]),
  },
  methods: {
    formatAddress,

    chooseAddress() {
      if (this.radioChosen != null) {
        if (this.addressType=='shipping') {
          this.$emit('shippingAddressSelected', this.addresses[this.radioChosen]);
        }
        if (this.addressType=='invoice') {
          this.$emit('billingAddressSelected', this.addresses[this.radioChosen]);
        }
      }
    }

  },
  mounted() {
    
  },
  updated() {
    this.radioChosen = null;
  }
};
</script>

<style lang="scss" scoped>
@import "~quasar-variables";

.q-card {
  background-color: white;
  height: 450px;
}

/*.q-radio {
  display: flex;
  justify-content: space-between;
  font-size: 50px;
}*/

.q-icon {
  color: v-bind("color_primary");
}

@media (min-width: 600px) {
  .q-dialog__inner--minimized > div {
    max-width: 560px;
    position: relative;
    left: 26%;
    min-width: 466px;
    top: 0px;
    min-height: 300px;
    border-radius: 25px 25px 25px 0px !important;
    box-shadow: 0px 8px 16px 0px #00000033;
  }
}

.q-dialog__inner--maximized > div {
  border-radius: 25px 25px 25px 0px !important;
  top: 200px;
  box-shadow: 0px 8px 16px 0px #00000033;
}

.q-btn {
  border-radius: 25px 25px 25px 0px;
  height: 48px;
  margin-top: 30px;
  text-transform: capitalize;
  width: 200px;
  color: white;
  background-color: v-bind("settings.color_primary");
}
</style>
